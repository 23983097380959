.btn-primary {
    border-bottom: 3px var(--border-primary) solid;
    background-color: var(--primary);
    border-left-color: var(--primary);
    border-top-color: var(--primary);
    border-right-color: var(--primary);
    color: var(--text-dark) !important;
    font-weight: 900;
}

.r2-btn-full-width {
    width: 100%;
}
.text-secondary{
    color: var(--secondary) !important;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    color: var(--text-dark);
    background-color: var(--primary);
    border-bottom: 3px var(--border-primary) solid;
    border-left-color: var(--primary);
    border-top-color: var(--primary);
    border-right-color: var(--primary);
    box-shadow: 0 0 0 0.2rem rgba(var(--primary));

    &:hover {
        visibility: visible;
        opacity: 1;
    }

    &:active {
        box-shadow: 0 0 0 0.2rem rgba(var(--primary));
        background-color: var(--primary);
        border-color: var(--primary);
    }
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary));
    background-color: var(--primary);
    border-color: var(--primary);
}
